import { defineStore } from 'pinia';

export const useHeaderStore = defineStore('header', () => {
    const storyblokApi = useStoryblokApi();
    const storyblokOptions = useStoryblokOptions();

    const header = ref<HeaderState | null>(null);
    const activeMegaMenuId = ref<PropertyKey>();

    const load = async () => {
        try {
            const response = await storyblokApi.get('cdn/stories/navigation/header-navigation', {
                ...storyblokOptions.value,
            });

            if (response.data && response?.data?.story) {
                header.value = (response?.data?.story?.content as unknown as HeaderState);
            }

        } catch (err: unknown) {
            const parsedError = JSON.parse(err as string) as TStoryBlokError;

            throw createError({
                statusCode: parsedError.status,
                statusMessage: parsedError.response + '/navigation/header-navigation',
                fatal: true,
            });
        }
    }

    return {
        header,
        activeMegaMenuId,
        load
    };
});

export default useHeaderStore;