export const useFooterStore = defineStore('footer', () => {
    const storyblokApi = useStoryblokApi();
    const storyblokOptions = useStoryblokOptions();

    const footer = ref<FooterState | null>(null);

    const resolvedAddress = computed(() => {
        if (footer.value) {
            return renderRichText(footer.value.address?.[0]?.text);
        }
        return null;
    });

    const load = async () => {
        try {
            const response = await storyblokApi.get('cdn/stories/navigation/footer-navigation', {
                ...storyblokOptions.value,
            });

            if (response.data && response?.data?.story) {
                footer.value = (response?.data?.story?.content as unknown as FooterState);
            }

        } catch (err: unknown) {
            const parsedError = JSON.parse(err as string) as TStoryBlokError;

            throw createError({
                statusCode: parsedError.status,
                statusMessage: parsedError.response + '/navigation/footer-navigation',
                fatal: true,
            });
        }
    }

    return {
        footer,
        resolvedAddress,
        load
    };
});

export default useFooterStore;