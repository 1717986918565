<script setup lang="ts">
import type { NavigationLinkStoryblok } from "@/storyblok/component-types-sb.d.ts";
import { useHeaderStore } from "~/store/header";

defineProps<{blok: NavigationLinkStoryblok}>();

const route = useRoute();
const headerStore = useHeaderStore();
const isInitialRender = ref(true);
const id = useId();

const isOpen = computed(() => {
  const isOpenMenu = headerStore?.activeMegaMenuId === id;
  return isOpenMenu;
});

const toggle = () => {
  if (isOpen.value && headerStore?.activeMegaMenuId){
    headerStore.activeMegaMenuId = undefined;
  } else {
    headerStore.activeMegaMenuId = id;
  }
}

const resetMegaMenu = () => (headerStore.activeMegaMenuId = undefined);

watch(
  () => route.path,
  async () => {
    resetMegaMenu();
  }
);

onMounted(async () => {
  isInitialRender.value = false;
});
</script>

<template>
  <template v-if="blok.children.length > 0">
      <BaseButton :aria-expanded="isOpen" @click="toggle" :hasAnimation="false" :class="[
        'font-semibold lg:font-normal',
        {'lg:font-semibold': isOpen}
      ]">
        {{ blok.name }}
        <Icon name="mdi:chevron-down" :class="[
          {'transform transition-transform': !isInitialRender},
          {'rotate-180': isOpen}
        ]"></Icon>
      </BaseButton>
      <div v-show="isOpen" class="flex flex-col gap-4 lg:p-8 xl:p-12 bg-brandDark lg:absolute bottom-0 transform transition-transform lg:translate-y-full lg:w-full lg:left-0 lg:grid lg:grid-cols-[1fr,2fr] lg:gap-8">
        <div class="grow-0 relative">
          <BaseImage v-bind="blok.childrenImage" aspectRatio="4/3" class="hidden lg:block"></BaseImage>
        </div>
        <div>
          <BaseHeadline :text="blok.name" tag="h4" :responsive="false" class="mb-8 hidden lg:block"></BaseHeadline>
          <ul v-show="isOpen" class="flex flex-col gap-4 lg:grid lg:grid-cols-[1fr,2fr] lg:gap-6">
            <li
                v-for="childItem of blok.children"
                :key="childItem.link.cached_url"
                class="inline-block w-full lg:w-auto"
            >
                <BaseLink v-bind="childItem.link" class="text-base pl-6 lg:p-0">{{
                  childItem.name
                }}</BaseLink>
            </li>
          </ul>
        </div>
      </div>
  </template>
  <BaseLink v-else v-bind="blok.link" class="font-semibold lg:font-normal">{{
    blok.name
  }}</BaseLink>
</template>
